import { Expose } from 'class-transformer';
import { TransformBoolean } from '@shared/class-transformer';

export class RegistrationRequest {
  @Expose({ name: 'temporary_code' })
  public code: string;

  @Expose()
  public email: string;

  @Expose()
  public password: string;

  @Expose({ name: 'confirm' })
  public repeatedPassword: string;

  @Expose()
  @TransformBoolean()
  public remember: boolean;

  @Expose({ name: 'is_marketing_enabled' })
  @TransformBoolean()
  public isMarketingEnabled: boolean;

  constructor(model: Partial<RegistrationRequest>) {
    Object.assign(this, model);
  }
}
